<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container">
                <!-- CONTENT -->
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-xl-6 offset-xl-6 col-lg-7 offset-lg-4 d-flex justify-content-center flex-column position-relative" style="overflow-y:hidden;">
                            <img src="@/assets/imgs/brown-line.svg" class="position-absolute brown-line"/> 
                            <div class="bg-white position-relative polygon">
                                <h1 class="text-black text-uppercase text-lg-end text-center">Palackba<br>zárt milliók!</h1>
                                <div class="text-center mt-5">
                                    <p class="text-black mb-2 fw-400 text-uppercase">A promóció időtartama:</p>
                                    <p class="hero__date-wrap text-primary text-uppercase fw-bold mb-0" >2024. november 15 – 2025. január 02. </p>
                                    <div class="text-center text-dark mt-4">
                                        <button v-if="$store.state.promoStatus != 'before'"
                                            class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn position-relative" style="z-index: 2;"
                                            v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                            @click="setGtm('Játszom', 'jatszom')"
                                        >
                                            Játszom!
                                        </button>
                                        <a class="btn btn-secondary position-relative me-0 ms-lg-2 order-md-1 mb-3 md-btn" style="z-index: 2;" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                                            Játékszabályzat
                                        </a>   
                                    </div>   
                                    <div class="empty"></div>
                                                                       
                                </div>
                                 <img src="@/assets/imgs/18.svg" class="img-fluid"/> 
                            </div>
                            
                        </div>
                        <img src="@/assets/imgs/hero-product.png" class="hero-product d-lg-block d-none" style="z-index: 1;"/>  
                        <img src="@/assets/imgs/hero-product-mobile.png" class="hero-product d-lg-none d-block"/>                 
                    </div>  
                </div>  
            </div>      
        </div>
        <div class="text-center py-2" style="background-color: #333333;">
            <p class="hero__info-text py-5 mb-0 text-white">A vásárlást igazoló eredeti bizonylatot a nyereményjáték lezárulta után további 1 hónapig <b>(2025. február 2-ig)</b>, illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni. A képek csak illusztrációk.</p>
        </div>
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'gomb_kattintas'
            })
        }
    }
}
</script>