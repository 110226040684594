<template>
    <div id="jatekszabaly" class="rules position-relative block--big">
        <div class="container-xl">

            <div class="row px-2">
                <h2 class="text-black text-center">
                    A játék lépései
                </h2>
                <div class="col-12 rules-container">
                    <!-- desctop -->
                    <ul 
                        class="rules-buttons nav nav-pills justify-content-center d-none d-md-flex w-100 pt-4 pb-3 position-relative" 
                        id="pills-tab" 
                        role="tablist">
                        <li 
                            v-for="(tab, tabind) in tabs"
                            :key="'tab-button-' + tab.id" 
                            role="presentation"
                            class="d-flex justify-content-center align-items-start">
                            <div 
                                class="rules-tab-button d-flex align-items-center" 
                                :class="tab.id == 1 ? 'active' : ''"
                                @click="setActiveTab(tab.id)"
                                data-bs-toggle="pill"
                                :data-bs-target="'#pills-' + tab.id"
                                role="tab"
                                aria-selected="true"
                            >
                                <div class="rules-tab-button-content d-flex flex-column">
                                    <div class="d-flex justify-content-center">
                                        <div class="rules-img text-center d-flex align-items-center justify-content-center flex-column">
                                            <!-- tab.imgActive -->
                                            <img 
                                                :src="tab.id == activeTab ? tab.imgActive : tab.img"
                                                class="img-fluid rules-icon" 
                                                alt="szabályok"
                                                :class="'rules-icon--'+tab.id"
                                                style="cursor:pointer;"
                                            >
                                            <!-- <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`">{{tab.name}}</div> -->
                                            <div class="text-center fs-18 text-uppercase text-primary" :style="tab.id == activeTab ? 'color:black!important;' : 'opacity:0.4;'">{{tab.name}}</div>
                                        </div>                                        
                                    </div>                                   
                                </div>
                                
                            </div>
                            <img v-if="tabind < tabs.length - 1" src="@/assets/imgs/step-sep.svg" class="mx-4 d-none" style="margin-top:54px"/>
                        </li>
                    </ul>
                    <!-- desktop -->
                    <div 
                        class="tab-content flex-1 d-none d-md-block" style="padding: 20px 60px;" 
                        id="pills-tabContent">                        
                            <div
                                v-for="tab in tabs"
                                :key="'tab-content-' + tab.id" 
                                class="tab-pane fade"
                                :class="tab.id == 1 ? 'show active' : ''" 
                                :id="'pills-' + tab.id" 
                                role="tabpanel" 
                                aria-labelledby="'pills-'+ tab.id + '-tab'"
                            >
                                <div 
                                    class="rules-tab-content d-flex align-items-center"
                                    :class="content[2] == '1' || content[2] == '2' || content[2] == '4' || content[2] == '5' || content[2] == '7' ? 'add-border w-100':''"
                                    v-for="(content, i) in tab.content" :key="i"
                                >  
                                    <p class="font-main m-0 text-black me-3 fw-700 pb-4 fs-30 fs-md-60" >
                                        <img :src="'imgs/'+content[0]" width="60" height="60">
                                        <!-- {{content[2]}}-->
                                    </p>
                                    <p class="text-black py-3 m-0 text-uppercase" 
                                        v-html="content[1]"></p>
                                </div>
                        </div>
                    </div>

                    <!-- MOBILE -->
                    <div id="gameRuleCarousel" class="carousel slide d-block d-md-none" data-bs-ride="carousel">                        
                        <div class="carousel-inner" >
                            <div class="carousel-item" data-bs-interval="8000"
                                :class="tab.id == 1 ? 'active' : ''"
                                v-for="tab in tabs"
                                :key="tab.id" >
                                <div class="rules-img rules-img--mobile text-center mb-5">
                                    <img 
                                        :src="tab.img" 
                                        class="img-fluid" 
                                        :alt="tab.name"
                                    >
                                    <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                    <div class="text-center fw-700 fs-22 text-primary mt-4 mb-4">{{tab.name}}</div>
                                </div>
                                
                                <div class="rules-tab-content d-flex align-items-center justify-content-center font-secondary mb-4"
                                        v-for="(content, i) in tab.content" :key="i">
                                    <div class="d-flex justify-content-center flex-column align-items-center align-items-md-start"
                                        :class="content[2] == '1' || content[2] == '2' || content[2] == '4' || content[2] == '5' || content[2] == '7' ? 'add-border w-100':''"
                                    >  
                                        <p class="font-main m-0 text-white pb-4 fw-700 fs-30 fs-md-60">
                                            <img :src="'imgs/'+content[0]" width="40" height="40">
                                           <!-- {{content[2]}} -->
                                        </p>
                                        <p class="text-black pb-4 m-0 text-uppercase" 
                                            v-html="content[1]" 
                                            
                                        ></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="prev" style="filter: invert(1);">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="next" style="filter: invert(1);">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>         

                <!-- BUTTONS      -->
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-flex py-7">
                    <button
                        class="btn btn-primary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        @click="setGtm('Játszom', 'jatszom')"
                    >
                        Játszom
                    </button>
                    <a class="btn btn-secondary " :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                        Játékszabályzat
                    </a> 
                </div>  
                <div class="col-12 text-center">
                    <div class="text-black good-luck fw-700 fs-24 text-uppercase">Jó játékot, sok szerencsét kívánunk!</div>
                </div>
                
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
            activeTab: 1,
             tabs: [ 
                {
                    id: 1,
                    name: "Vásárolj!",
                    img: "imgs/vasarolj.svg",
                    imgActive: "imgs/vasarolj-active.svg",
                    content:[
                        ["step-1.svg",`Vásárolj legalább <b>1 palack</b> a promócióban részt vevő <a href="#termekek" style="font-weight:bold;" class='text-black'>0,75 l-es Törley terméket</a>, hogy részt vehess a játékban.`, "1"],
                        ["step-2.svg",`A nyereményjáték időtartama: <b><br>2024. november 15 – <span style='display:inline-block;'>2025. Január 02.</span></b>`, "2"],
                        [
                            "step-3.svg","<b>FIGYELEM!</b> A játékban nem lehetséges részt venni olyan bizonylattal, amelyen a Törley termék vásárlása nem egyértelműen megállapítható, és amelynek megnevezése nem pénztárgép által rányomtatva szerepel (pl. gyűjtőblokkok)!", "3"
                        ]
                    ]
                },
                {
                    id: 2,
                    name: "Regisztrálj!",
                    img: "imgs/regisztralj.svg",
                    imgActive: "imgs/regisztralj-active.svg",
                    content: [
                        [
                            "step-4.svg","Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!", "4"
                        ],
                        [
                            "step-5.svg","<b>A vásárlást igazoló eredeti bizonylat</b> sértetlen, ép állapotban történő megőrzése <strong style='white-space:nowrap'>2025. február 2-ig</strong> szükséges.", "5"
                        ],
                        [
                            "step-6.svg","Ne feledd, minél <b>többször vásárolsz</b> a promócióban részt vevő Törley termékekből legalább 1 palackot, annyiszor <b>több esélyed van a nyerésre!</b>", "6"
                        ],
                                             
                    ]
                    
                },
                {
                    id: 3,
                    name: "Nyerj!",
                    img: "imgs/nyerj.svg",
                    imgActive: "imgs/nyerj-active.svg",
                    content: [
                        [
                            "step-7.svg","A játék ideje alatt előre meghatározott <b>nyerőidőpontokban</b> (7 és 21 óra között) megnyerhető <b>pénznyeremény a nyertes bankszámlájára utalva:<ul><li>óránként 1 db 5.000 Ft,</li><li>hetente 11 db 20.000 Ft és 150.000 Ft közötti meglepetés összeg</b> (részletek a játékszabályzatban)</li></ul>", "7"
                        ],
                        [
                            "step-8.svg","<b>A fődíj 1 db 1.002.025 Ft</b> pénznyeremény a nyertes bankszámlájára utalva. A játék lezárását követően <b>a fődíj sorsolása: <span style='display: inline-block;'>2025. január 6. 14 óra.</span></b>", "8"
                        ]
                    ]
                    
                }               
            ]
        }
    },
    methods:{
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'button': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
            })
        },
        setActiveTab(index){
            this.activeTab = index;
        }
    }
}
</script>