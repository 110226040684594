<template>
    <div class="py-5 faq" id="faq">
        <div class="container">
            <div class="row position-relative py-5" id="accordionExample">
                <div class="col-12 col-lg-8 offset-lg-2 text-center mb-4 cap metal-thing-sec">
                    <h2 class="text-black ">Gyakran ismételt kérdések</h2>   
                </div>
                <div class="col-12 col-lg-8 offset-lg-2 bg-white mb-3" style="border-radius: 8px;" v-for="(item, index) in questions" :key="index">
                    <button 
                    class="btn btn-collapse collapsed"  
                    type="button" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="'#collapse'+index" 
                    aria-expanded="false" 
                    :aria-controls="'#collapse'+index">
                        {{ item.question }}
                    </button>
                    <div class="collapse font-secondary fs-14 pb-3" :id="'collapse'+index" aria-labelledby="headingOne" data-bs-parent="#accordionExample" v-html="item.answer">
                    </div>
                </div> 
            </div>       
        </div>
    </div>
</template>

<script>

export default {
    
  mounted() {

      this.$store.dispatch("getQuestions");

      setTimeout(() => {
        let jsz = document.getElementById('jsz')
        let jsz2 = document.getElementById('jsz2')
                       
        jsz.setAttribute('href', this.$store.state.file_url)
        jsz2.setAttribute('href', this.$store.state.file_url)
      }, 200);

  },
  computed:{
    questions(){
      return this.$store.state.questions;
    },
  },
}
</script>