<template>
    <div id="termekek" class="products block--big position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center">
                    <h2 class="font-header text-center mb-7 text-primary">
                        Részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 col-lg-8 text-center offset-lg-2 mb-5">
                    <div class="mx-auto text-black">
                       A játékban a 0,75 literes Törley termékek vesznek részt, melyeket Magyarországon található kiskereskedelmi üzletben vagy magánszemélyeket kiszolgáló online webáruházban vagy nagykereskedelmi áruházban vásároltak. A választék áruházanként eltérő lehet.<br><br>
                       Alább megtekinthető a játékban részt vevő teljes választék a  <a style="color: #BAA978;" href="https://pezsgowebshop.hu/" target="_blank">https://pezsgowebshop.hu/</a> oldalon.
                    </div>
                </div>
                <div class="col-12 text-center my-3">
                    <VueSlickCarousel v-bind="settings">
                            <div class="bg-white p-3 product-box"> 
                                <img src="imgs/products/products1.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Gála sec</p>
                                <a href="https://pezsgowebshop.hu/torley-gala-sec-0-75l-532" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products2.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Muscosecco sec</p>
                                <a href="https://pezsgowebshop.hu/torley-muscosecco-517" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products3.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Talisman demi sec</p>
                                <a href="https://pezsgowebshop.hu/torley-talisman-demi-sec-535" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products4.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Fortuna doux</p>
                                <a href="https://pezsgowebshop.hu/torley-fortuna-doux-533" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products5.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Muscateller doux</p>
                                <a href="https://pezsgowebshop.hu/torley-muscateller-doux-534" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products6.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Charmant doux</p>
                                <a href="https://pezsgowebshop.hu/torley-charmant-doux-0-75l-526" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products7.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Charmant rosé doux</p>
                                <a href="https://pezsgowebshop.hu/torley-charmant-rose-doux-0-75l-530" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products8.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Charmant rouge doux</p>
                                <a href="https://pezsgowebshop.hu/torley-charmant-rouge-doux-536" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products9.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Excellence Chardonnay extra sec</p>
                                <a href="https://pezsgowebshop.hu/torley-excellence-chardonnay-extra-sec-514" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                            <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products10.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Excellence Sárgamuskotály doux</p>
                                <a href="https://pezsgowebshop.hu/torley-excellence-sargamuskotaly-doux-516" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products11.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley ICE white edition</p>
                                <a href="https://pezsgowebshop.hu/torley-ice" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products12.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley PiNK ICE pink edition</p>
                                <a href="https://pezsgowebshop.hu/torley-ice-pink" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products13.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Tokaji doux</p>
                                <a href="https://pezsgowebshop.hu/torley-tokaji-doux-524" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products14.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Tokaji brut</p>
                                <a href="https://pezsgowebshop.hu/torley-tokaji-brut" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products15.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Chardonnay brut</p>
                                <a href="https://pezsgowebshop.hu/torley-chardonnay-brut-2016-518" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products16.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Chardonnay brut nature</p>
                                <a href="https://pezsgowebshop.hu/torley-nyerspezsgo-chardonnay-519" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products17.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Alkoholmentes fehér</p>
                                <a href="https://pezsgowebshop.hu/torley-alkoholmentes-feher-0-75l-537" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                             <div class="bg-white p-3 product-box">
                                <img src="imgs/products/products18.png" alt="torley" class="img-fluid mx-auto">
                                <p class="text-primary">Törley Alkoholmentes rosé</p>
                                <a href="https://pezsgowebshop.hu/torley-alkoholmentes-rose-539" target="_blank" class="btn btn-primary btn-prod">Megveszem</a>
                            </div>
                    </VueSlickCarousel>
                    
                </div> 
                <!--<div class="col-12 text-center my-5">                    
                    <a class="btn btn-primary" @click="setGtm('Termékek listája')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>-->
                             
                <div class="col-12 mt-5">
                    <div class="d-flex flex-column">
                        <p class="text-center text-black fs-14" >A képek csak illusztrációk.</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
            arrows: false,
            dots: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                     autoplaySpeed: 3000,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'termekek',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'termekek'
            })
        }
    }
}
</script>